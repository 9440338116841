<template>
  <page-header-wrapper>
    <a-card :bordered="false">

      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
      </s-table>

      <place-form
        ref="createModal"
        :visible="formVisible"
        :loading="confirmLoading"
        :model="formModel"
        @cancel="handleCancel"
        @ok="handleSave"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import PlaceForm from '@/views/arcade/module/PlaceForm'
import { createRedeemGroup, getRedeemCodeList } from '@/api/web'

export default {
  name: 'RedeemCodeGroupList',
  components: {
    STable,
    PlaceForm
  },
  data () {
    return {
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        groupId: this.$route.params.id
      },
      loadData: parameter => {
        return getRedeemCodeList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '兑换码',
          dataIndex: 'code',
          sorter: true
        },
        {
          title: '使用用户',
          dataIndex: 'userId',
          sorter: true
        },
        {
          title: '使用时间',
          dataIndex: 'useTime',
          sorter: true
        },
        {
          title: '兑换内容',
          dataIndex: 'content'
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.$refs.createModal.form.resetFields()
      this.formModel = null
      this.formVisible = true
    },
    handleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          createRedeemGroup(values).then(() => {
            this.formVisible = false
            this.confirmLoading = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('创建')
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.formVisible = false
    }
  }
}
</script>

<style scoped>

</style>
